<template>
  <!-- /图库版本/ -->

  <div class="home">
    <el-card>
      <div slot="header">
        <span>{{ $t("add.upload3D") }}</span>
        <el-button type="text" style="margin-left: 20px" @click="$router.go(-1)"> 返回</el-button>
        <el-button type="text" style="margin-left: 20px" @click="updateAudit" v-if="editFlag && form.auditStatus == 2">
          撤回审核</el-button>
      </div>

      <div class="uploadList">
        <el-upload v-if="!editFlag" class="upload-demo" drag :action="$uploadUrl + '/common/zipUpload'"
                   :on-success="handleModel" :file-list="fileList" multiple :headers="token">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ $t("add.clickAdd3D") }}</div>
          <!-- <div class="el-upload__text">{{ $t("add.PNGformat") }}</div> -->
        </el-upload>
        <el-upload v-if="!editFlag" class="upload-demo" drag :action="$uploadUrl + '/common/upload'"
                   :on-success="handleThumbnail" multiple :show-file-list="false" :headers="token"
                   style="margin-left: 10px">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ $t("add.clickAddPictures") }}</div>
          <!-- <div class="el-upload__text">{{ $t("add.PNGformat") }}</div> -->
        </el-upload>

        <div class="upload-demo" v-loading="loading" :element-loading-text="$t('details.loading')"
             element-loading-spinner="el-icon-loading">
          <model-obj :src="form.modelUrl" @on-load="onLoad" :mtl="form.secImg" :lights="lights" style="height: 140px">
          </model-obj>
        </div>

        <div class="imgItem" v-for="(item, index) in imgList" :key="index">
          <img :src="item" alt="" />
          <el-button type="text" @click="delImg(index)" v-if="!editFlag">删除</el-button>
        </div>
      </div>
    </el-card>

    <el-form ref="form" :model="form" class="form" style="display: flex; flex-wrap: wrap" :disabled="editFlag"
             :rules="rules">
      <el-card style="width: 100%">
        <div slot="header">
          <span>图像信息</span>
        </div>
        <div class="flex wrap jsb">
          <el-form-item :label="$t('details.provider')" prop="provider">
            <el-input v-model="form.provider" dd="请输入贡献者" />
          </el-form-item>
          <el-form-item :label="$t('details.txgs')" prop="picFormat">
            <el-input v-model="form.picFormat" dd="请输入图像格式" />
          </el-form-item>
          <el-form-item :label="$t('details.dpi')" prop="dpi">
            <el-input v-model="form.dpi" dd="请输入分辨率" />
          </el-form-item>
          <el-form-item :label="$t('details.keyWords')" prop="keyWords">
            <el-input v-model="form.keyWords" dd="请输入关键词" />
          </el-form-item>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>古生物分类信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.Broadcategory')" prop="trivialName">
            <el-input v-model="form.trivialName" dd="请输入大类-对应类别门或纲" />
          </el-form-item>

          <el-form-item label="大类-对应类别门或纲" prop="trivialNameEn">
            <el-input v-model="form.trivialNameEn" dd="请输入大类-对应类别门或纲" />
          </el-form-item>
          <el-form-item :label="$t('details.phylum')" prop="phylum">
            <el-input v-model="form.phylum" dd="请输入门" />
          </el-form-item>
          <el-form-item label="门-英文" prop="phylumEn">
            <el-input v-model="form.phylumEn" dd="请输入门-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.classA')" prop="classA">
            <el-input v-model="form.classA" dd="请输入纲" />
          </el-form-item>
          <el-form-item label="纲-英文" prop="classEn">
            <el-input v-model="form.classEn" dd="请输入纲-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.subclass')" prop="subclass">
            <el-input v-model="form.subclass" dd="请输入亚纲" />
          </el-form-item>
          <el-form-item label="亚纲-英文" prop="subclassEn">
            <el-input v-model="form.subclassEn" dd="请输入亚纲-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.infraclass')" prop="infraclass">
            <el-input v-model="form.infraclass" dd="请输入次纲" />
          </el-form-item>
          <el-form-item label="次纲-英文" prop="infraclassEn">
            <el-input v-model="form.infraclassEn" dd="请输入次纲-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.superorder')" prop="superorder">
            <el-input v-model="form.superorder" dd="请输入超目" />
          </el-form-item>
          <el-form-item label="超目-英文" prop="superorderEn">
            <el-input v-model="form.superorderEn" dd="请输入超目-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.orderA')" prop="orderA">
            <el-input v-model="form.orderA" dd="请输入目" />
          </el-form-item>
          <el-form-item label="目-英文" prop="orderEn">
            <el-input v-model="form.orderEn" dd="请输入目-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.suborder')" prop="suborder">
            <el-input v-model="form.suborder" dd="请输入亚目" />
          </el-form-item>
          <el-form-item label="亚目-英文" prop="suborderEn">
            <el-input v-model="form.suborderEn" dd="请输入亚目-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.infraorder')" prop="infraorder">
            <el-input v-model="form.infraorder" dd="请输入次目" />
          </el-form-item>
          <el-form-item label="次目-英文" prop="infraorderEn">
            <el-input v-model="form.infraorderEn" dd="请输入次目-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.superfamily')" prop="superfamily">
            <el-input v-model="form.superfamily" dd="请输入超科" />
          </el-form-item>
          <el-form-item label="超科-英文" prop="superfamilyEn">
            <el-input v-model="form.superfamilyEn" dd="请输入超科-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.family')" prop="family">
            <el-input v-model="form.family" dd="请输入科" />
          </el-form-item>
          <el-form-item label="科-英文" prop="familyEn">
            <el-input v-model="form.familyEn" dd="请输入科-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.subfamily')" prop="subfamily">
            <el-input v-model="form.subfamily" dd="请输入亚科" />
          </el-form-item>
          <el-form-item label="亚科-英文" prop="subfamilyEn">
            <el-input v-model="form.subfamilyEn" dd="请输入亚科-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.genus')" prop="genus">
            <el-input v-model="form.genus" dd="请输入属" />
          </el-form-item>
          <el-form-item label="属-英文" prop="genusEn">
            <el-input v-model="form.genusEn" dd="请输入属-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.species')" prop="species">
            <el-input v-model="form.species" dd="请输入种名" />
          </el-form-item>
          <el-form-item label="种名-英文" prop="speciesEn">
            <el-input v-model="form.speciesEn" dd="请输入种名-英文" />
          </el-form-item>
          <el-form-item label="命名人-英文" prop="nomenclatorEn">
            <el-input v-model="form.nomenclatorEn" dd="请输入命名人-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.defineTime')" prop="defineTime">
            <el-input v-model="form.defineTime" dd="请输入命名人-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.revisedSpecies')" prop="revisedSpecies">
            <el-input v-model="form.revisedSpecies" dd="请输入修订属种" />
          </el-form-item>
          <el-form-item label="修订属种-英文" prop="revisedSpeciesEn">
            <el-input v-model="form.revisedSpeciesEn" dd="请输入修订属种-英文" />
          </el-form-item>
          <div style="width: 240px"></div>
          <div style="width: 240px"></div>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>化石标本信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.doi')" prop="doi">
            <el-input v-model="form.doi" dd="请输入文献DOI" />
          </el-form-item>
          <el-form-item :label="$t('details.reference')" prop="reference">
            <el-input v-model="form.reference" dd="请输入发表文献" />
          </el-form-item>
          <el-form-item label="发表文献-英文" prop="referenceEn">
            <el-input v-model="form.referenceEn" dd="请输入发表文献-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.funding')" prop="funding">
            <el-input v-model="form.funding" dd="请输入资助来源" />
          </el-form-item>
          <el-form-item :label="$t('details.typeSpecimen')" prop="typeSpecimen">
            <el-input v-model="form.typeSpecimen" dd="请输入模式情况" />
          </el-form-item>
          <el-form-item :label="$t('details.prefix')" prop="prefix">
            <el-input v-model="form.prefix" dd="请输入标本号前缀" />
          </el-form-item>
          <el-form-item :label="$t('details.specimentsId')" prop="specimentsId">
            <el-input v-model="form.specimentsId" dd="请输入标本登记号" />
          </el-form-item>
          <el-form-item :label="$t('details.fieldNumber')" prop="fieldNumber">
            <el-input v-model="form.fieldNumber" dd="请输入野外编号" />
          </el-form-item>
          <el-form-item :label="$t('details.plate')" prop="plate">
            <el-input v-model="form.plate" dd="请输入图版编号" />
          </el-form-item>
          <el-form-item :label="$t('details.fig')" prop="fig">
            <el-input v-model="form.fig" dd="请输入图片号" />
          </el-form-item>
          <el-form-item :label="$t('details.housedIn')" prop="housedIn">
            <el-input v-model="form.housedIn" dd="请输入馆藏地" />
          </el-form-item>
          <el-form-item :label="$t('details.cabinet')" prop="cabinet">
            <el-input v-model="form.cabinet" dd="请输入馆藏柜/厨" />
          </el-form-item>
          <div style="width: 240px"></div>
          <div style="width: 240px"></div>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>地质信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.eon')" prop="eon">
            <el-input v-model="form.eon" dd="请输入时代-宙" />
          </el-form-item>
          <el-form-item :label="$t('details.era')" prop="era">
            <el-input v-model="form.era" dd="请输入时代-代" />
          </el-form-item>
          <el-form-item :label="$t('details.period')" prop="period">
            <el-input v-model="form.period" dd="请输入时代-纪" />
          </el-form-item>
          <el-form-item :label="$t('details.epoch')" prop="epoch">
            <el-input v-model="form.epoch" dd="请输入时代-世" />
          </el-form-item>
          <el-form-item :label="$t('details.age')" prop="age">
            <el-input v-model="form.age" dd="请输入时代-期" />
          </el-form-item>
          <el-form-item label="时代-英文" prop="ageEn">
            <el-input v-model="form.ageEn" dd="请输入时代-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.ageFrom')" prop="ageFrom">
            <el-input v-model="form.ageFrom" dd="请输入首现年龄/Ma" />
          </el-form-item>
          <el-form-item :label="$t('details.ageTo')" prop="ageTo">
            <el-input v-model="form.ageTo" dd="请输入末现年龄/Ma" />
          </el-form-item>
          <el-form-item :label="$t('details.ageMid')" prop="ageMid">
            <el-input v-model="form.ageMid" dd="请输入平均年龄/Ma" />
          </el-form-item>
          <el-form-item :label="$t('details.section')" prop="section">
            <el-input v-model="form.section" dd="请输入剖面" />
          </el-form-item>
          <el-form-item label="剖面-英文" prop="sectionEn">
            <el-input v-model="form.sectionEn" dd="请输入剖面-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.horizon')" prop="horizon">
            <el-input v-model="form.horizon" dd="请输入层位" />
          </el-form-item>
          <el-form-item label="层位-英文" prop="horizonEn">
            <el-input v-model="form.horizonEn" dd="请输入层位-英文" />
          </el-form-item>
          <div style="width: 240px"></div>
          <div style="width: 240px"></div>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>地理信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item :label="$t('details.locality')" prop="locality">
            <el-input v-model="form.locality" dd="请输入产地" />
          </el-form-item>
          <el-form-item label="产地-英文" prop="localityEn">
            <el-input v-model="form.localityEn" dd="请输入产地-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.country')" prop="country">
            <el-input v-model="form.country" dd="请输入国家" />
          </el-form-item>
          <el-form-item label="国家-英文" prop="countryEn">
            <el-input v-model="form.countryEn" dd="请输入国家-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.province')" prop="province">
            <el-input v-model="form.province" dd="请输入省" />
          </el-form-item>
          <el-form-item label="省-英文" prop="provinceEn">
            <el-input v-model="form.provinceEn" dd="请输入省-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.city')" prop="city">
            <el-input v-model="form.city" dd="请输入市" />
          </el-form-item>
          <el-form-item label="市-英文" prop="cityEn">
            <el-input v-model="form.cityEn" dd="请输入市-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.county')" prop="county">
            <el-input v-model="form.county" dd="请输入县" />
          </el-form-item>
          <el-form-item label="县-英文" prop="countyEn">
            <el-input v-model="form.countyEn" dd="请输入县-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.village')" prop="village">
            <el-input v-model="form.village" dd="请输入村" />
          </el-form-item>
          <el-form-item label="村-英文" prop="villageEn">
            <el-input v-model="form.villageEn" dd="请输入村-英文" />
          </el-form-item>
          <el-form-item :label="$t('details.longitude')" prop="longitude">
            <!-- <el-input v-model="form.longitude" dd="请输入经度" /> -->
            <el-input-number v-model="form.longitude" :precision="9" :step="0.1" :max="10"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('details.latitude')" prop="latitude">
            <!-- <el-input v-model="form.latitude" dd="请输入纬度" /> -->
            <el-input-number v-model="form.latitude" :precision="9" :step="0.1" :max="10"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('details.attitude')" prop="attitude">
            <el-input v-model="form.attitude" dd="请输入高程" />
          </el-form-item>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>其他详细信息</span>
        </div>
        <div class="flex wrap jsb clearfix">
          <el-form-item label="描述" prop="description">
            <el-input v-model="form.description" cols="30" type="textarea" dd="" />
          </el-form-item>
          <el-form-item label="描述-英文" prop="descriptionEn">
            <el-input v-model="form.descriptionEn" cols="30" type="textarea" dd="" />
          </el-form-item>
          <el-form-item label="备注" prop="notes">
            <el-input v-model="form.notes" cols="30" type="textarea" dd="" />
          </el-form-item>
          <el-form-item label="备注-英文" prop="notesEn">
            <el-input v-model="form.notesEn" cols="30" type="textarea" dd="" />
          </el-form-item>

          <el-form-item label="图文件名" prop="pictureName">
            <el-input v-model="form.pictureName" dd="" />
          </el-form-item>
          <el-form-item label="设备名称与型号" prop="pictureName">
            <el-input v-model="form.deviceNameAndType" dd="" />
          </el-form-item>

          <el-form-item label="录入员" prop="input">
            <el-input v-model="form.input" dd="" />
          </el-form-item>
          <el-form-item label="校对员" prop="checkA">
            <el-input v-model="form.checkA" dd="" />
          </el-form-item>
        </div>
      </el-card>
    </el-form>

    <div class="btn" @click="goPublish" v-if="!editFlag">
      {{ $t("add.issue") }}
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

import ImageApi from "@/api/image/index";
import commonApi from "@/api/common/index";
import { ModelObj } from "vue-3d-model";

export default {
  name: "addImg",
  components: {
    ModelObj,
  },
  data() {
    return {
      editFlag: false,
      form: {},
      imgList: [],
      tokenInfo: "",
      token: {},
      fileList: [],
      loading: false,
      lights: [
        {
          type: "HemisphereLight",
          position: { x: 0, y: 0, z: 0 },
          skyColor: 0xffffff,
          intensity: 1,
        },
        {
          type: "DirectionalLight",
          position: { x: 0, y: 0, z: 0 },
          color: 0xffffff,
          intensity: 0.8,
        },
      ],
      // 表单校验
      rules: {
        phylum: [{ required: true, message: '请输入门', trigger: 'blur' }],
        phylumEn: [{ required: true, message: '请输入门英文', trigger: 'blur' }],
        // trivialName: [{ required: true, message: '请输入大类', trigger: 'blur' }],
        // trivialNameEn: [{ required: true, message: '请输入大类英文', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.token = {
      Authorization: getToken(),
    };
    this.$route.query.id && this.initImg(this.$route.query.id);
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.query) {
        this.form = {};
        this.imgList = [];
        this.$route.query.id && this.initImg(this.$route.query.id);
      }
    },
  },
  methods: {
    onLoad() {
      this.loading = false;
    },
    handleModel(res) {
      this.zipFileUrl = res.url;
    },
    async goPublish() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.zipFileUrl) {
            this.form.zipFileUrl = this.zipFileUrl;
          }
          if (this.$isObjEqual(this.form)) {
            return this.$message.error("请填写古生物分类信息");
          }
          this.form.coverImg = this.imgList.join("&");
          if (this.form.infoId != null) {
            const res = await ImageApi.addModel3D(this.form);
            if (res.code == 200) {
              this.imgList = [];

              this.$message.success("修改成功");
              this.editFlag = false;
              this.$confirm("是否提交审核?", "提示", {
                confirmButtonText: "提交审核",
                cancelButtonText: "返回",
                type: "warning",
              })
                .then(() => {
                  this.$message({
                    type: "success",
                    message: "提交成功!",
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
              this.$router.push({
                path: "user",
              });
            }
          } else {
            if (this.imgList.length <= 0) {
              return this.$message.warning("请上传封面");
            }
            const res = await ImageApi.addModel3D(this.form);
            if (res.code == 200) {
              this.form = {};
              this.$message.success("添加成功");
              this.editFlag = false;
              this.imgList = [];
              this.$router.push({
                path: "/",
              });
            }
          }
        } else {
          return this.$message.warning("部分关键信息未录入，请检查")
        }
      })

    },

    async updateAudit() {
      const res = await commonApi.updateAudit({ auditId: this.form.auditId });
      if (res.code == 200) {
        this.editFlag = false;
        this.$route.query.id && this.initImg(this.$route.query.id);
      }
    },
    async initImg(id) {
      const { data: res } = await ImageApi.UserModelInfo(id);
      this.form = res;
      this.imgList = this.form.coverImg.split("&");
      if (this.form.auditStatus == 2) {
        this.editFlag = true;
      }
    },
    handleThumbnail(res) {
      this.imgList.push(res.url);
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1400px;
  margin: 0 auto;
  margin-top: 14px;
  padding-bottom: 50px;
  height: auto !important;
  // background: #fff;

  .el-card {
    padding: 0 20px;
    margin-top: 20px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-input {
      width: 240px;
    }
  }

  .btn {
    width: 572px;
    height: 50px;
    background: #2b82e3;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
    margin: 35px auto;
  }

  .uploadList {
    display: flex;
    padding-bottom: 50px;

    flex-wrap: wrap;

    .imgItem {
      width: 280px;
      height: 182px;
      margin-bottom: 40px;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.imgList {
  display: flex;
  flex-wrap: wrap;

  .imgItem {
    width: 312px;
    height: 182px;
    margin-left: 28px;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
